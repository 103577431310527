import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  isLoading: "loading",
  assetDataDetails: {
    count: null,
  },
  assetData: null,
  assetAttachHistory: null,
  newAsset: null,
  newAssetError: null,
  noDeviceAsset: null,
  noDeviceAssetStatus: null,
  editedAsset: null,
  editedAssetError: null,
  attachedAsset: null,
  attachedAssetError: null,
  detachedAsset: null,
  detachedAssetError: null,
  searchAssetList: null,
  assetTypeBB: {
    accessShaftNodes: null,
    shaftOutline: null,
    trunkMh: null,
    trunkMhOutline: null,
    workSiteAssets: null,
    pubAccessShaftNodes: null,
    outfallMh: null,
    outfallMhOutline: null,
  },
};

export const getAllAssets = createAsyncThunk(
  "getAllAssets",
  async (payload) => {
    const response = await axios.get(payload.url);

    const { results, count } = response.data;

    if (count <= 100) {
      return response.data;
    }
    const requests = [];
    for (let j = 1; j < count / 100; j++) {
      requests.push(axios.get(`${payload.url}&offset=${j * 100}`));
    }

    const dataArr = await Promise.all(requests).then((responses) =>
      responses.reduce((acc, res) => acc.concat(res.data.results), results)
    );

    return { results: dataArr } ;

  }
);

export const getNoDeviceAsset = createAsyncThunk(
  "getNoDeviceAsset",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response.data.results;
  }
);

export const getAssetTypeBB = createAsyncThunk(
  "getAssetTypeBB",
  async (payload) => {
    let response = await axios.get(payload.url);
    return response;
  }
);

export const getAssetAttachHistory = createAsyncThunk(
  "getAssetAttachHistory",

  async (payload) => {
    let response = await axios.get(payload.url);
    return response;
  }
);

export const searchAsset = createAsyncThunk("searchAsset", async (payload) => {
  const response = await axios.get(payload.url);
  return response;
});

export const addNewAsset = createAsyncThunk("addNewAsset", async (payload) => {
  const response = await axios.post(payload.url, payload.data);
  return response;
});

export const editAsset = createAsyncThunk("editAsset", async (payload) => {
  const response = await axios.patch(payload.url, payload.data);
  return response;
});

export const attachAsset = createAsyncThunk("attachAsset", async (payload) => {
  const response = await axios.post(payload.url, payload.data);
  return response;
});

export const detachAsset = createAsyncThunk("detachAsset", async (payload) => {
  const response = await axios.patch(payload.url);
  return response;
});

const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    reset(state, action) {
      state.assetData = null;
    },
    resetAssetAttachHistory(state, action) {
      state.assetAttachHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssetAttachHistory.pending, (state, action) => {
      state.assetAttachHistory = null;
      state.isLoading = APIStatus.loading;
    });
    builder.addCase(getAssetAttachHistory.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.assetAttachHistory = action.payload.data.results;
    });
    builder.addCase(getAssetAttachHistory.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
    });

    builder.addCase(getAllAssets.pending, (state, action) => {
      state.assetData = null;
      state.isLoading = APIStatus.loading;
    });
    builder.addCase(getAllAssets.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.assetDataDetails.count = action.payload.count;
      state.assetData = action.payload.results;
    });
    builder.addCase(getAllAssets.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
    });

    builder.addCase(getNoDeviceAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.noDeviceAssetStatus = APIStatus.loading;
    });
    builder.addCase(getNoDeviceAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.noDeviceAssetStatus = APIStatus.loaded;
      state.noDeviceAsset = action.payload;
    });
    builder.addCase(getNoDeviceAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.noDeviceAssetStatus = APIStatus.failed;
    });

    builder.addCase(addNewAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.newAsset = null;
      state.newAssetError = null;
    });
    builder.addCase(addNewAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.newAsset = action.payload;
      state.newAssetError = null;
    });
    builder.addCase(addNewAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.newAsset = null;
      state.newAssetError = action.error.message;
    });
    builder.addCase(editAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.editedAsset = null;
      state.editedAssetError = null;
    });
    builder.addCase(editAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.editedAsset = action.payload;
      state.editedAssetError = null;
    });
    builder.addCase(editAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.editedAsset = null;
      state.editedAssetError = action.error.message;
    });
    builder.addCase(attachAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.attachedAsset = null;
      state.attachedAssetError = null;
    });
    builder.addCase(attachAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.attachedAsset = action.payload;
      state.attachedAssetError = null;
    });
    builder.addCase(attachAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.attachedAssetError = action.error.message;
      state.attachedAsset = null;
    });
    builder.addCase(detachAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
      state.detachedAsset = null;
      state.detachedAssetError = null;
    });
    builder.addCase(detachAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      state.detachedAsset = action.payload;
      state.detachedAssetError = null;
    });
    builder.addCase(detachAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
      state.detachedAsset = null;
      state.detachedAssetError = action.error.message;
    });
    builder.addCase(searchAsset.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
    });
    builder.addCase(searchAsset.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;

      state.searchAssetList = action.payload.data.results;
    });
    builder.addCase(searchAsset.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
    });

    builder.addCase(getAssetTypeBB.pending, (state, action) => {
      state.isLoading = APIStatus.loading;
    });
    builder.addCase(getAssetTypeBB.fulfilled, (state, action) => {
      state.isLoading = APIStatus.loaded;
      let value = action.payload.config.url;

      if (value !== null) {
        let trimdat = value.split("asset_type=");
        let assetType = trimdat.pop();

        switch (assetType) {
          case "geofence_pub_access_shaft_nodes":
            state.assetTypeBB.accessShaftNodes = action.payload.data;
            break;

          case "geofence_pub_shaft_outlines":
            state.assetTypeBB.shaftOutline = action.payload.data;
            break;

          case "geofence_pub_trunk_mh":
            state.assetTypeBB.trunkMh = action.payload.data;
            break;

          case "geofence_pub_trunk_mh_outlines":
            state.assetTypeBB.trunkMhOutline = action.payload.data;
            break;

          case "geofence_pub_access_shaft":
            state.assetTypeBB.pubAccessShaftNodes = action.payload.data;
            break;

          case "geofence_pub_outfall_mh":
            state.assetTypeBB.outfallMh = action.payload.data;
            break;

          case "geofence_pub_outfall_mh_outlines":
            state.assetTypeBB.outfallMhOutline = action.payload.data;
            break;

          case "work_site":
            state.assetTypeBB.workSiteAssets = action.payload.data;
            break;
          default:
            console.log("Invalid Selection->", assetType);
        }
      }
    });
    builder.addCase(getAssetTypeBB.rejected, (state, action) => {
      state.isLoading = APIStatus.failed;
    });
  },
});

export const { reset, resetAssetAttachHistory } = assetSlice.actions;

export default assetSlice.reducer;
